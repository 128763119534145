<template>
  <v-card
    v-bind="propsCompSheet"
    data-cy="folder-tree"
    :loading="status.loading"
  >
    <h2 class="my-4">{{ $t("folders.title") }}</h2>

    <div class="d-flex justify-end mb-4">
      <!-- View menu -->
      <v-menu v-if="modifyMenu" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text icon v-bind="attrs" v-on="on" class="mr-2">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="modifyOwners">
            <v-list-item-title @click="clickViewOwners">
              <v-icon class="mr-2">{{ viewOwnersIcon }}</v-icon>
              {{ $t("users.owner") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="modifyFileName">
            <v-list-item-title @click="clickViewFileName">
              <v-icon class="mr-2">{{ viewFileNameIcon }}</v-icon>
              {{ $t("files.fileName") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="modifyFileSize">
            <v-list-item-title @click="clickViewFileSize">
              <v-icon class="mr-2">{{ viewFileSizeIcon }}</v-icon>
              {{ $t("files.fileSize") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="modifyPublicPrivate">
            <v-list-item-title @click="clickViewPublicPrivate">
              <v-icon class="mr-2">{{ viewPublicPrivateIcon }}</v-icon>
              {{ $t("files.publicPrivate") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Create new folder -->
      <v-btn
        v-if="authCan('create.folders')"
        text
        icon
        @click.stop="clickFolderCreate()"
      >
        <v-icon>mdi-folder-plus</v-icon>
      </v-btn>
    </div>

    <div v-if="!foldersTree.length">
      <p>{{ $t("empty") }}</p>
    </div>

    <template v-if="foldersTree.length">
      <v-text-field
        v-model="editSearch"
        outlined
        clearable
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>

      <v-treeview
        ref="treeView"
        :items="foldersTreeFiltered"
        :search="editSearch"
        :open.sync="foldersOpen"
        :filter="customFilter"
        open-on-click
      >
        <!-- Item icon -->
        <template v-slot:prepend="{ item, open }">
          <div class="d-flex">
            <v-icon v-if="!item.fileType" large color="treeFolder">
              {{ open ? "mdi-folder-open" : "mdi-folder" }}
            </v-icon>

            <v-icon v-else large color="treeFile" @click="clickFile(item)">
              {{ fileIcons[item.fileType] }}
            </v-icon>
          </div>
        </template>
        <!-- Item name and info -->
        <template v-slot:label="{ item }">
          <div class="d-flex flex-column my-2" @click="clickFile(item)">
            <div class="d-flex align-center">
              <span>{{ item.name }}</span>
              <span v-if="item.fileName && viewFileName"
                >&nbsp;&mdash;&nbsp;{{ item.fileName }}</span
              >
              <span v-if="viewOwners"
                >&nbsp;&mdash;&nbsp;{{ getOwnerName(item.ownerId) }}</span
              >
            </div>
            <div
              class="d-flex align-center font-weight-thin"
              style="font-size: 0.85rem"
            >
              <template v-if="item.hasFileSize && viewFileSize">
                <span>{{ item.fileSize }}</span>
              </template>

              <template v-if="item.createdTime && viewAccessLog">
                <!-- Divider -->
                <span v-if="item.hasFileSize && viewFileSize" class="mx-1"
                  >&nbsp;&mdash;&nbsp;</span
                >
                <span>
                  {{ $t("documents.created") }}:
                  {{ item.createdTime }}
                </span>
              </template>

              <template v-if="item.accessedTime && viewAccessLog">
                <!-- Divider -->
                <span
                  v-if="(item.hasFileSize && viewFileSize) || item.createdTime"
                  class="mx-1"
                  >&nbsp;&mdash;&nbsp;</span
                >
                <span>
                  {{ $t("documents.accessed") }}:
                  {{ item.accessedTime }}
                </span>
              </template>
            </div>
          </div>
        </template>
        <!-- Item buttons and menu -->
        <template v-slot:append="{ item }">
          <!-- Shared link -->
          <v-tooltip v-if="item.isShared" bottom>
            <template v-slot:activator="{ on, attrs }" bottom>
              <v-btn
                color="fileShared"
                fab
                x-small
                elevation="0"
                :to="{
                  name: 'documents-public-show',
                  params: { token: item.shareToken },
                }"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("common.shared") }}</span>
          </v-tooltip>

          <!-- Public / private -->
          <v-tooltip v-if="viewPublicPrivate" bottom>
            <template v-slot:activator="{ on, attrs }" bottom>
              <v-btn text icon v-bind="attrs" v-on="on">
                <v-icon :color="item.private ? 'filePrivate' : 'filePublic'">
                  {{ item.private ? "$filePrivate" : "$filePublic" }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{
              item.private ? $t("common.private") : $t("common.public")
            }}</span>
          </v-tooltip>

          <!-- Create folder -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }" bottom>
              <v-btn
                v-if="!item.fileType && authCan('create.folders')"
                text
                icon
                @click.stop="clickFolderCreate(item.id)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-folder-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("folders.createTitle") }}</span>
          </v-tooltip>

          <!-- Create document -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }" bottom>
              <v-btn
                v-if="!item.fileType && authCan('create.documents')"
                text
                icon
                @click.stop="clickDocumentCreate(item.id)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-file-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("documents.createTitle") }}</span>
          </v-tooltip>

          <!-- Document access -->
          <v-tooltip v-if="viewAccessLog" bottom>
            <template v-slot:activator="{ on, attrs }" bottom>
              <v-btn
                v-if="item.fileType"
                text
                icon
                @click.stop="
                  $router.push({
                    name: 'documents-access-show',
                    params: { id: item.bdId },
                  })
                "
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-list-box-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("documentsAccess.title") }}</span>
          </v-tooltip>

          <!-- Document download -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }" bottom>
              <v-btn
                v-if="item.fileType"
                text
                icon
                :href="getUrlDownload(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("common.download") }}</span>
          </v-tooltip>

          <!-- Folder menu -->
          <v-menu
            offset-y
            v-if="
              !item.fileType &&
              (authCanByUserId('editOwn.folders', item.ownerId) ||
                authCan('editOthers.folders'))
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :to="{ name: 'folders-edit', params: { id: item.bdId } }"
              >
                <v-list-item-title>{{ $t("common.edit") }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  authCanByUserId('deleteOwn.folders', item.ownerId) ||
                  authCan('deleteOthers.folders')
                "
                :to="{ name: 'folders-delete', params: { id: item.bdId } }"
              >
                <v-list-item-title>{{ $t("common.delete") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- File menu -->
          <v-menu
            offset-y
            v-if="
              item.fileType &&
              (authCanByUserId('editOwn.documents', item.ownerId) ||
                authCan('editOthers.documents'))
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :to="{ name: 'documents-edit', params: { id: item.bdId } }"
              >
                <v-list-item-title>{{ $t("common.edit") }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  authCanByUserId('deleteOwn.documents', item.ownerId) ||
                  authCan('deleteOthers.documents')
                "
                :to="{ name: 'documents-delete', params: { id: item.bdId } }"
              >
                <v-list-item-title>{{ $t("common.delete") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-treeview>
    </template>

    <v-alert v-model="status.showReadError" dismissible type="error">
      {{ $t("folders.error") }}
    </v-alert>
  </v-card>
</template>

<script>
import APP_CONSTANTS from "@/appConstants";
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import MiscUtils from "@/modules/base/miscUtils.mixin";
import Permissions from "@/modules/auth/mixins/Permissions.mixin";
import { mapGetters } from "vuex";

export default {
  name: "FoldersTree",
  mixins: [ComponentStatus, Permissions, MiscUtils],
  props: {
    foldersOpenIn: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => ({
    valid: false,

    editSearch: null,

    // This has to match the OrganisationEditAppearance.vue
    viewOwners: false,
    viewFileName: true,
    viewFileSize: false,
    viewPublicPrivate: true,
    viewAccessLog: true,

    modifyOwners: true,
    modifyFileName: true,
    modifyFileSize: true,
    modifyPublicPrivate: true,

    fileIcons: {
      unknown: "mdi-file",
      "application/pdf": "mdi-file-pdf-box",
      "image/png": "mdi-file-image",
      "image/jpeg": "mdi-file-image",
      "application/msword": "mdi-file-word",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        "mdi-file-word",
      html: "mdi-language-html5",
      js: "mdi-nodejs",
      json: "mdi-json",
      md: "mdi-markdown",
      txt: "mdi-file-document-outline",
      xls: "mdi-file-excel",
    },

    foldersOpen: [],
  }),
  computed: {
    modifyMenu() {
      return (
        this.modifyOwners ||
        this.modifyFileName ||
        this.modifyFileSize ||
        this.modifyPublicPrivate
      );
    },
    foldersTree: function () {
      let treeFolders = [];
      let tempFolders = this.folders;
      let childrenPointers = { null: treeFolders };
      let childrenIds = [null];
      let maxRec = 0;

      // Calculating the folder structure
      //------------------------------------------------------------------
      // Starting from the root folder (null) we will look for any folder
      // that has the parent_folder_id matching the current folder id and
      // add it as a children thanks to the "pointer" to the child nodes.
      while (childrenIds.length > 0) {
        // We take off the childrenIds array the id of the folder we
        // want to process.
        let folderId = childrenIds.shift();
        // We filter to find the folders that have that specific parent.
        let nodeFolders = tempFolders.filter((folder) => {
          return folder.parentFolderId === folderId;
        });
        // TODO: implement some view sorting mechanism.
        nodeFolders.sort((a, b) => a.id > b.id);
        // We change the folder data structure to map the treeview
        // data structure.
        let nodeFoldersTree = nodeFolders.map((folder) => {
          return {
            id: folder.id,
            bdId: folder.id,
            name: folder.name,
            children: [],
            private: folder.private,
            // createdTime: folder.createdTime,
            ownerId: folder.ownerId,
          };
        });
        // Adding the new folders to the current children of the
        // folder treeview structure.
        childrenPointers[folderId].push(...nodeFoldersTree);
        // We create new childrenPointers nodes for the new folders
        // added in case they have children nodes as well. We also
        // add their ids to the childrenIds list of folders to
        // process for children nodes.
        for (let i = 0; i < nodeFoldersTree.length; i++) {
          childrenPointers[nodeFoldersTree[i].id] = nodeFoldersTree[i].children;
          childrenIds.push(nodeFoldersTree[i].id);
        }

        // Just in case there are circular references
        maxRec++;
        if (maxRec > 500) {
          break;
        }
      }

      // Calculating the file structure
      //------------------------------------------------------------------
      // We simply loop through the files and put it in the right folder
      // children node created above.
      let tempDocuments = this.documents;
      for (let i = 0; i < tempDocuments.length; i++) {
        // Checking we have a valid fileType and if not we put
        // a default one.
        let fileType = tempDocuments[i].fileType;
        if (!fileType) {
          fileType = "unknown";
        } else {
          if (!this.fileIcons[tempDocuments[i].fileType]) {
            fileType = "unknown";
          }
        }
        // We change the document data structure to map the treeview
        // data structure.
        let nodeFileTree = {
          id: `d_${tempDocuments[i].id}`,
          bdId: tempDocuments[i].id,
          name: tempDocuments[i].name,
          fileName: tempDocuments[i].fileName,
          hasFileSize: tempDocuments[i].fileSize,
          fileSize: this.prettifyDiskSize(tempDocuments[i].fileSize),
          fileType: fileType,
          private: tempDocuments[i].private,
          createdTime: tempDocuments[i].createdTime,
          accessedTime: tempDocuments[i].accessedTime,
          ownerId: tempDocuments[i].ownerId,
          isShared: tempDocuments[i].documentShares.length > 0,
        };
        if (nodeFileTree.isShared) {
          nodeFileTree.shareToken = tempDocuments[i].documentShares[0].token;
        }
        if (tempDocuments[i].folderId) {
          if (tempDocuments[i].folderId in childrenPointers) {
            childrenPointers[tempDocuments[i].folderId].push(nodeFileTree);
          } else {
            // eslint-disable-next-line
            console.log(
              "Document with no folder: ",
              tempDocuments[i].id,
              tempDocuments[i].folderId
            );
          }
        } else {
          childrenPointers[null].push(nodeFileTree);
        }
      }

      return treeFolders;
    },
    foldersTreeFiltered: function () {
      let treeFoldersFiltered = this.foldersTree;
      let userRole = this.$store.getters["auth/getUserRole"];

      if (userRole === APP_CONSTANTS.USER_ROLES_USER_R) {
        // console.log(treeFoldersFiltered);
        if (this.foldersTree.length === 1) {
          treeFoldersFiltered = this.foldersTree[0].children;
        }
      }

      return treeFoldersFiltered;
    },
    viewOwnersIcon: function () {
      if (this.viewOwners) {
        return "mdi-checkbox-marked";
      }
      return "mdi-checkbox-blank-outline";
    },
    viewFileNameIcon: function () {
      if (this.viewFileName) {
        return "mdi-checkbox-marked";
      }
      return "mdi-checkbox-blank-outline";
    },
    viewFileSizeIcon: function () {
      if (this.viewFileSize) {
        return "mdi-checkbox-marked";
      }
      return "mdi-checkbox-blank-outline";
    },
    viewPublicPrivateIcon: function () {
      if (this.viewPublicPrivate) {
        return "mdi-checkbox-marked";
      }
      return "mdi-checkbox-blank-outline";
    },
    ...mapGetters({
      folders: "folders/readAll",
      documents: "documents/readAll",
      organisationAppearance: "auth/getOrganisationAppearance",
    }),
  },
  created() {
    this.getViewMenuFromLocalstorage();
    this.setStatusReading();
    Promise.all([
      this.$store.dispatch("folders/readAll"),
      this.$store.dispatch("documents/readAll"),
      this.$store.dispatch("users/readAll"),
    ])
      .then((/* result */) => {
        if (
          Array.isArray(this.foldersOpenIn) &&
          this.foldersOpenIn.length > 0
        ) {
          this.foldersOpen = this.foldersOpenIn.map((value) => {
            return Number(value);
          });
        }
        // Defining the watcher after the tree component and the data has
        // been initialised to avoid a couple of initial changes that
        // produced an error on the javascript console (without side
        // effects but now the output is clean).
        this.$watch("foldersOpen", function (newValue, oldValue) {
          let newValueSort = newValue.slice(0).sort((a, b) => {
            return a - b;
          });
          let oldValueSort = oldValue.slice(0).sort((a, b) => {
            return a - b;
          });
          if (newValueSort.toString() !== oldValueSort.toString()) {
            this.$emit("folders-open-change", newValueSort);
          }
        });
        this.setStatusReadSuccess();
      })
      .catch((/* error */) => {
        this.setStatusReadError();
      });
  },
  watch: {
    editSearch(newValue) {
      if (newValue) {
        this.$refs.treeView.updateAll(true);
      } else {
        this.$refs.treeView.updateAll(false);
      }
    },
    organisationAppearance: {
      immediate: true,
      handler: function (newValue) {
        let userRole = this.$store.getters["auth/getUserRole"];

        if (newValue && "treeViewOptions" in newValue) {
          if (userRole === APP_CONSTANTS.USER_ROLES_USER_RW) {
            // Modify options
            if ("modifyOwnersUserRW" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.modifyOwnersUserRW === true) {
                this.modifyOwners = true;
              } else {
                this.modifyOwners = false;
              }
            }
            if ("modifyFileNameUserRW" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.modifyFileNameUserRW === true) {
                this.modifyFileName = true;
              } else {
                this.modifyFileName = false;
              }
            }
            if ("modifyFileSizeUserRW" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.modifyFileSizeUserRW === true) {
                this.modifyFileSize = true;
              } else {
                this.modifyFileSize = false;
              }
            }
            if ("modifyPublicPrivateUserRW" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.modifyPublicPrivateUserRW === true) {
                this.modifyPublicPrivate = true;
              } else {
                this.modifyPublicPrivate = false;
              }
            }

            // View options
            if ("viewOwnersUserRW" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.viewOwnersUserRW === true) {
                this.viewOwners = true;
              } else {
                this.viewOwners = false;
              }
            }
            if ("viewFileNameUserRW" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.viewFileNameUserRW === true) {
                this.viewFileName = true;
              } else {
                this.viewFileName = false;
              }
            }
            if ("viewFileSizeUserRW" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.viewFileSizeUserRW === true) {
                this.viewFileSize = true;
              } else {
                this.viewFileSize = false;
              }
            }
            if ("viewPublicPrivateUserRW" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.viewPublicPrivateUserRW === true) {
                this.viewPublicPrivate = true;
              } else {
                this.viewPublicPrivate = false;
              }
            }
            if ("viewAccessLogUserRW" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.viewAccessLogUserRW === true) {
                this.viewAccessLog = true;
              } else {
                this.viewAccessLog = false;
              }
            }

            this.saveViewMenuToLocalstorage();
          } else if (userRole === APP_CONSTANTS.USER_ROLES_USER_R) {
            // Modify options
            if ("modifyOwnersUserR" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.modifyOwnersUserR === true) {
                this.modifyOwners = true;
              } else {
                this.modifyOwners = false;
              }
            }
            if ("modifyFileNameUserR" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.modifyFileNameUserR === true) {
                this.modifyFileName = true;
              } else {
                this.modifyFileName = false;
              }
            }
            if ("modifyFileSizeUserR" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.modifyFileSizeUserR === true) {
                this.modifyFileSize = true;
              } else {
                this.modifyFileSize = false;
              }
            }
            if ("modifyPublicPrivateUserR" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.modifyPublicPrivateUserR === true) {
                this.modifyPublicPrivate = true;
              } else {
                this.modifyPublicPrivate = false;
              }
            }

            // View options
            if ("viewOwnersUserR" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.viewOwnersUserR === true) {
                this.viewOwners = true;
              } else {
                this.viewOwners = false;
              }
            }
            if ("viewFileNameUserR" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.viewFileNameUserR === true) {
                this.viewFileName = true;
              } else {
                this.viewFileName = false;
              }
            }
            if ("viewFileSizeUserR" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.viewFileSizeUserR === true) {
                this.viewFileSize = true;
              } else {
                this.viewFileSize = false;
              }
            }
            if ("viewPublicPrivateUserR" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.viewPublicPrivateUserR === true) {
                this.viewPublicPrivate = true;
              } else {
                this.viewPublicPrivate = false;
              }
            }
            if ("viewAccessLogUserR" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.viewAccessLogUserR === true) {
                this.viewAccessLog = true;
              } else {
                this.viewAccessLog = false;
              }
            }

            this.saveViewMenuToLocalstorage();
          }
        }
      },
    },
  },
  methods: {
    getOwnerName(ownerId) {
      let userName = this.$t("users.unknown");
      let user = this.$store.getters["users/readById"](ownerId);
      if (user.id) {
        if (user.name) {
          userName = user.name;
        }
        if (user.surnames) {
          if (user.name) {
            userName += " ";
          }
          userName += user.surnames;
        }
      }
      return userName;
    },
    customFilter(item, queryText) {
      let textOne = "";
      let textTwo = "";
      const searchText = queryText.toString().toLowerCase();

      if (item && item.name) {
        textOne = item.name.toLowerCase();
      }
      if (item && item.fileName) {
        textTwo = item.fileName.toLowerCase();
      }

      // console.log(
      //   "kkk",
      //   `[${queryText}]`,
      //   `[${textOne}]`,
      //   textOne.indexOf(searchText)
      // );

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    getViewMenuFromLocalstorage() {
      let viewMenuStr = localStorage.getItem("viewMenu");
      if (viewMenuStr) {
        let viewMenu = JSON.parse(viewMenuStr);
        if ("viewOwners" in viewMenu) {
          if (viewMenu.viewOwners) {
            this.viewOwners = true;
          } else {
            this.viewOwners = false;
          }
        }
        if ("viewFileName" in viewMenu) {
          if (viewMenu.viewFileName) {
            this.viewFileName = true;
          } else {
            this.viewFileName = false;
          }
        }
        if ("viewFileSize" in viewMenu) {
          if (viewMenu.viewFileSize) {
            this.viewFileSize = true;
          } else {
            this.viewFileSize = false;
          }
        }
        if ("viewPublicPrivate" in viewMenu) {
          if (viewMenu.viewPublicPrivate) {
            this.viewPublicPrivate = true;
          } else {
            this.viewPublicPrivate = false;
          }
        }
      }
    },
    saveViewMenuToLocalstorage() {
      let viewMenu = {
        viewOwners: this.viewOwners,
        viewFileName: this.viewFileName,
        viewFileSize: this.viewFileSize,
        viewPublicPrivate: this.viewPublicPrivate,
      };
      localStorage.setItem("viewMenu", JSON.stringify(viewMenu));
    },
    clickViewOwners() {
      if (this.viewOwners) {
        this.viewOwners = false;
      } else {
        this.viewOwners = true;
      }
      this.saveViewMenuToLocalstorage();
    },
    clickViewFileName() {
      if (this.viewFileName) {
        this.viewFileName = false;
      } else {
        this.viewFileName = true;
      }
      this.saveViewMenuToLocalstorage();
    },
    clickViewFileSize() {
      if (this.viewFileSize) {
        this.viewFileSize = false;
      } else {
        this.viewFileSize = true;
      }
      this.saveViewMenuToLocalstorage();
    },
    clickViewPublicPrivate() {
      if (this.viewPublicPrivate) {
        this.viewPublicPrivate = false;
      } else {
        this.viewPublicPrivate = true;
      }
      this.saveViewMenuToLocalstorage();
    },
    clickFile(item) {
      if (item && item.fileType) {
        this.$router.push({
          name: "documents-show",
          params: { id: item.bdId },
        });
      }
    },
    getUrlDownload(item) {
      let apiUrl = this.$store.getters["config/getApiUrl"];
      return `${apiUrl}/documents/${item.bdId}/download`;
    },
    clickFolderCreate(folderId) {
      this.$router.push({
        name: "folders-create",
        params: { folderId: folderId },
      });
    },
    clickDocumentCreate(folderId) {
      this.$router.push({
        name: "documents-create",
        params: { folderId: folderId },
      });
    },
  },
};
</script>
