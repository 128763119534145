<template>
  <v-container v-bind="propsPageMainContainer" data-cy="documents-index-page">
    <v-row v-bind="propsPageMainRow">
      <v-col v-bind="propsPageMainCol">
        <FoldersTree
          :foldersOpenIn="foldersOpenIn"
          @folders-open-change="foldersOpenChange"
        ></FoldersTree>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FoldersTree from "../components/FoldersTree.vue";

export default {
  name: "DocumentsIndexPage",
  components: {
    FoldersTree,
  },
  data: () => ({
    foldersOpenIn: [],
    foldersOpen: [],
  }),
  created() {
    if (this.$route.query.open && this.$route.query.open.length > 0) {
      if (Array.isArray(this.$route.query.open)) {
        this.foldersOpenIn = this.$route.query.open.slice(0);
      } else {
        this.foldersOpenIn = [this.$route.query.open];
      }
    }
  },
  computed: {
    userId() {
      let userId = "";
      if (this.$route.params.id) {
        userId = this.$route.params.id;
      }
      return userId;
    },
  },
  methods: {
    foldersOpenChange: function (value) {
      this.foldersOpen = value;
      let queryData = Object.assign({}, this.$route.query);
      queryData["open"] = this.foldersOpen;
      this.$router.push({ name: "documents-index", query: queryData });
    },
  },
};
</script>
